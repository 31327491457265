
import { interpolate } from "/services/helpers"
import breakpoints from 'assets/vars/breakpoints.json'

borderRadiusDesktop = 32
borderRadiusMobile = 8

export default
	props: block: Object

	data: ->

		shouldAnimateBorders: false

		borderRadius: null

	inject: ["blockIndex"]

	computed:
		classes: -> [
			if @block.ignoreGutters then "ignore-gutters max-w-full" else "max-w-wide"
			@block.horizontalAlignment || "left"
			"marquee" if @blockIndex == 0
			"height-#{ @block.height || 'full' }"
		]

		textColorStyle: -> color: @block.textColor || "black"

		imageClasses: -> [
			"overlay-intensity-#{@block.overlayIntensity || 'low'}" if @block.overlayIntensity != "no-overlay"
			"overlay-type-#{@block.overlayType || 'solid'}" if @block.overlayIntensity != "no-overlay"
		]

		imageDesktop: -> if @block.imageDesktop.length then @block.imageDesktop else @block.imageMobile
		imageMobile: -> if @block.imageMobile.length then @block.imageMobile else @block.imageDesktop

		videoDesktop: -> if @block?.videoDesktop?.length then @block.videoDesktop else @block.videoMobile
		videoMobile: -> if @block?.videoMobile?.length then @block.videoMobile else @block.videoDesktop

		shouldExpand: -> @block.height == 'full' and !@block.ignoreGutters and @block.expand

		desktopBreakpoint: -> parseInt breakpoints.desktop

		mobileBreakpoints: -> parseInt breakpoints['mobile-sm']

		computedBorderRadius: ->
			return 0 if @block.ignoreGutters
			@borderRadius if @shouldAnimateBorders

		viewportWidth: -> @$store.state.layout.viewport.width

		radius: -> interpolate borderRadiusDesktop, borderRadiusMobile, @mobileBreakpoints, @desktopBreakpoint, @viewportWidth

	mounted: ->	@$waitForPageTransition @setupMotion

	# Cleanup animations
	destroyed: -> @onDestroyed()

	methods:
		play: ->
			if @block.libraryVideo?.length then return @$store.commit 'videoModal/play', {video: @block?.libraryVideo?[0]?.videoDesktop?[0] || @block?.libraryVideo?[0]?.videoMobile?[0], title: @block?.libraryVideo?[0]?.title, subtitles: @block?.libraryVideo?[0]?.subtitles, videoLibaryId: @block?.libraryVideo?[0]?.id }

			else return @$store.commit 'videoModal/play', @block.youtubeVideoUrl

		setupMotion: ->	@cleanupMotionAllSizes = @$setupReducedMotion @setupMotionAllSizes

		setupMotionAllSizes: ->
			expandedState = if @shouldExpand then {borderRadius:0, ease: 'linear', maxWidth: 2560, padding: 0} else {}
			bkgWrapExpandedState = if @shouldExpand then {borderRadius: 0, ease: 'linear'} else {}
			squircleExpandedState = if @shouldExpand then {borderRadius: 0} else {}

			@timeline1 = @$gsap.timeline()
				.pause()
				.set @$refs.bkgDesktop.$el, {scale: 1.1}
				.set @$refs.bkgMobile.$el, {scale: 1.1}
				.to @$refs.bkgDesktop.$el, 10, {scale: 1, ease: 'linear'}, 0
				.to @$refs.bkgMobile.$el, 10, {scale: 1, ease: 'linear'}, 0
				.addLabel 'end', 10

			@timeline2 = @$gsap.timeline()
				.pause()
				.set @, {borderRadius: @radius, shouldAnimateBorders: true}
				.to @$el, 10, expandedState, 0
				.to @$refs.bkg.$el, 10, bkgWrapExpandedState, 0
				.to @, 10, squircleExpandedState, 0
				.addLabel 'end', 10

			trigger1Position = if @blockIndex == 0 then { start: "top top", end: "center top" } else { start: "top 40%", end: "top 0%"}

			@trigger1 = @$ScrollTrigger.create {
				trigger: @$refs.pin
				...trigger1Position
				animation: @timeline1
			}

			@trigger2 = @$ScrollTrigger.create
				trigger: @$refs.pin
				start: 'top 40%'
				end: 'top 0%'
				animation: @timeline2

			@$ScrollTrigger.refresh(true)

		onDestroyed: ->
			@cleanupMotionAllSizes?()
			@trigger1?.kill()
			@trigger2?.kill()

